
const STORYTYPE_ALL = { value: '', label: 'Story Type' };
const STORYTYPE_LEAD = { value: 'LEAD', label: 'Lead' };
const STORYTYPE_PIC_LEAD = { value: 'PIC_LEAD', label: 'Pic Lead' };
const STORYTYPE_PIC_STORY = { value: 'PIC_STORY', label: 'Pic Story' };
const STORYTYPE_DOWN_PAGE = { value: 'DOWN_PAGE', label: 'Down page' };
const STORYTYPE_DOWN_PAGE_PIC = {
    value: 'DOWN_PAGE_WITH_PIC',
    label: 'Down page with pic'
};

const STORYTYPE_BRIEF = { value: 'BRIEF', label: 'Brief' };
const STORYTYPE_BRIEF_PIC = {
    value: 'BRIEF_WITH_PIC',
    label: 'Brief with pic'
};
const STORYTYPE_FEATURE_SINGLE_IMAGE = {
    value: 'FEATURE_WITH_SINGLE_IMAGE',
    label: 'Feature with single image'
};
const STORYTYPE_FEATURE_MULTIPLE_IMAGE = {
    value: 'FEATURE_WITH_MULTIPLE_IMAGES',
    label: 'Feature with multiple images'
};
const STORYTYPE_MULTI_PIC = {
    value: 'MULTI_PIC_STORY',
    label: 'Multi-pic story'
};
const STORYTYPE_EXPLAINER = { value: 'EXPLAINER', label: 'Explainer' };
const STORYTYPE_VIDEO = { value: 'VIDEO', label: 'Video' };
const STORYTYPE_GALLERY = { value: 'GALLERY', label: 'Gallery' };
const STORYTYPE_STORY_MAP = { value: 'STORY_MAP', label: 'StoryMap' };
const STORYTYPE_QA = {
    value: 'QA',
    label: 'Q&A'
};
const STORYTYPE_SHORTHAND = { value: 'SHORTHAND', label: 'Shorthand' };
const STORYTYPE_LIVE_BLOG24 = {
    value: 'LIVE_BLOG24',
    label: 'LiveBlog24'
};
const STORYTYPE_IMOVIE = {
    value: 'IMOVIE',
    label: 'iMovie'
};
const STORYTYPE_LIVE_TWEETS = {
    value: 'LIVE_TWEETS',
    label: 'Live Tweets'
};
const STORYTYPE_POLL = {
    value: 'POLL',
    label: 'Poll'
};
const STORYTYPE_QUIZ = {
    value: 'QUIZ',
    label: 'Quiz'
};
const STORYTYPE_HIDDEN = {
    value: 'HIDDEN',
    label: 'Hidden'
};
const STORYTYPE_AUDIO = {
    value: 'AUDIO',
    label: 'Audio'
};

// Sections
const SECTION_ALL = { value: '', label: 'Section' };
const SECTION_NEWS = { value: 'NEWS', label: 'News' };
const SECTION_SPORT = { value: 'SPORT', label: 'Sport' };
const SECTION_COMMUNITY = { value: 'COMMUNITY', label: 'Community' };
const SECTION_BUSINESS = { value: 'BUSINESS', label: 'Business' };
const SECTION_OPINION = { value: 'OPINION', label: 'Opinion' };
const SECTION_ENTERTAINMENT = {
    value: 'ENTERTAINMENT',
    label: 'Entertainment'
};
const SECTION_COURT = { value: 'COURT', label: 'Court' };
const SECTION_REALESTATE = {
    value: 'REAL ESTATE',
    label: 'Real Estate'
};
const SECTION_RURAL = { value: 'RURAL', label: 'Rural' };
const SECTION_BEEFCATTLE = {
    value: 'BEEF_CATTLE',
    label: 'Beef & Cattle'
};
const SECTION_DAIRY = { value: 'DAIRY', label: 'Dairy' };
const SECTION_SHEEP = { value: 'SHEEP', label: 'Sheep' };
const SECTION_WOOL = { value: 'WOOL', label: 'Wool' };
const SECTION_AGRIBUSINESS = {
    value: 'AGRIBUSINESS',
    label: 'Agribusiness'
};
const SECTION_GRAINS = { value: 'GRAINS', label: 'Grains' };

// Sessions
const SESSION_ALL = { value: '', label: 'Session' };
const SESSION_MORNING = { value: 'Morning', label: 'Morning' };
const SESSION_AFTERNOON = { value: 'Afternoon', label: 'Afternoon' };
const SESSION_EVENING = { value: 'Evening', label: 'Evening' };
const SESSION_ONHOLD = { value: 'Onhold', label: 'On-hold' };

// Story Channels
const CHANNEL_WEB = { value: 'WEB', label: 'Web' };
const CHANNEL_PRINT = { value: 'PRINT', label: 'Print' };
const CHANNEL_WEB_PRINT = { value: 'WEB_PRINT', label: 'Web & Print', defaultValue: true };

const NEWSLIST_ORGANISTAION_IDS = [22, 206];

// Options

const storyTypeDigital = [
    STORYTYPE_ALL,
    STORYTYPE_EXPLAINER,
    STORYTYPE_VIDEO,
    STORYTYPE_GALLERY,
    STORYTYPE_STORY_MAP,
    STORYTYPE_QA,
    STORYTYPE_SHORTHAND,
    STORYTYPE_LIVE_BLOG24,
    STORYTYPE_IMOVIE,
    STORYTYPE_LIVE_TWEETS,
    STORYTYPE_POLL,
    STORYTYPE_QUIZ,
    STORYTYPE_HIDDEN,
    STORYTYPE_AUDIO
];

const storyTypePrint = [
    STORYTYPE_ALL,
    STORYTYPE_LEAD,
    STORYTYPE_PIC_LEAD,
    STORYTYPE_PIC_STORY,
    STORYTYPE_DOWN_PAGE,
    STORYTYPE_DOWN_PAGE_PIC,
    STORYTYPE_BRIEF,
    STORYTYPE_BRIEF_PIC,
    STORYTYPE_GALLERY,
    STORYTYPE_FEATURE_SINGLE_IMAGE,
    STORYTYPE_FEATURE_MULTIPLE_IMAGE,
    STORYTYPE_MULTI_PIC
];

const storyTypeOptions = [STORYTYPE_ALL].concat(
    storyTypeDigital
        .filter(function(v) { return v.value !== ''})
        .concat(storyTypePrint.filter(function(v) { return v.value !== '' }))
);

const sectionOptions = [
    SECTION_ALL,
    SECTION_NEWS,
    SECTION_SPORT,
    SECTION_COMMUNITY,
    SECTION_BUSINESS,
    SECTION_OPINION,
    SECTION_ENTERTAINMENT,
    SECTION_COURT,
    SECTION_REALESTATE,
    SECTION_RURAL,
    SECTION_BEEFCATTLE,
    SECTION_DAIRY,
    SECTION_SHEEP,
    SECTION_WOOL,
    SECTION_AGRIBUSINESS,
    SECTION_GRAINS
];

const sessionOptions = [
    SESSION_ALL,
    SESSION_MORNING,
    SESSION_AFTERNOON,
    SESSION_EVENING,
    SESSION_ONHOLD
];

const channelOptions = [CHANNEL_WEB, CHANNEL_PRINT, CHANNEL_WEB_PRINT];

const DATE_RANGE_HELD_OVER = { value: "DATE_RANGE_HELD_OVER", label: "Held Over (before today)"};
const DATE_RANGE_TODAY = { value: "DATE_RANGE_TODAY", label: "Today"};
const DATE_RANGE_NEXT_WEEK = { value: "DATE_RANGE_NEXT_WEEK", label: "Next 7 days"};
const DATE_RANGE_NEXT_MONTH = { value: "DATE_RANGE_NEXT_MONTH", label: "Next 30 days"};


const dateRanges = [
    DATE_RANGE_HELD_OVER,
    DATE_RANGE_TODAY,
    DATE_RANGE_NEXT_WEEK,
    DATE_RANGE_NEXT_MONTH
];

module.exports = {
    // STORY CHANNELS
    CHANNEL_WEB: CHANNEL_WEB,
    CHANNEL_PRINT: CHANNEL_PRINT,
    CHANNEL_WEB_PRINT: CHANNEL_WEB_PRINT,

    // STORY SESSIONS

    SESSION_ALL: SESSION_ALL,
    SESSION_MORNING: SESSION_MORNING,
    SESSION_AFTERNOON: SESSION_AFTERNOON,
    SESSION_EVENING: SESSION_EVENING,
    SESSION_ONHOLD: SESSION_ONHOLD,

    // STORY SECTIONS

    SECTION_ALL: SECTION_ALL,
    SECTION_NEWS: SECTION_NEWS,
    SECTION_SPORT: SECTION_SPORT,
    SECTION_COMMUNITY: SECTION_COMMUNITY,
    SECTION_BUSINESS: SECTION_BUSINESS,
    SECTION_OPINION: SECTION_OPINION,
    SECTION_ENTERTAINMENT: SECTION_ENTERTAINMENT,
    SECTION_COURT: SECTION_COURT,
    SECTION_REALESTATE: SECTION_REALESTATE,
    SECTION_RURAL: SECTION_RURAL,
    SECTION_BEEFCATTLE: SECTION_BEEFCATTLE,
    SECTION_DAIRY: SECTION_DAIRY,
    SECTION_SHEEP: SECTION_SHEEP,
    SECTION_WOOL: SECTION_WOOL,
    SECTION_AGRIBUSINESS: SECTION_AGRIBUSINESS,
    SECTION_GRAINS: SECTION_GRAINS,

    // STORY TYPES

    STORYTYPE_ALL: STORYTYPE_ALL,
    STORYTYPE_LEAD: STORYTYPE_LEAD,
    STORYTYPE_PIC_LEAD: STORYTYPE_PIC_LEAD,
    STORYTYPE_PIC_STORY: STORYTYPE_PIC_STORY,
    STORYTYPE_DOWN_PAGE: STORYTYPE_DOWN_PAGE,
    STORYTYPE_DOWN_PAGE_PIC: STORYTYPE_DOWN_PAGE_PIC,
    STORYTYPE_BRIEF: STORYTYPE_BRIEF,
    STORYTYPE_BRIEF_PIC: STORYTYPE_BRIEF_PIC,
    STORYTYPE_FEATURE_SINGLE_IMAGE: STORYTYPE_FEATURE_SINGLE_IMAGE,
    STORYTYPE_FEATURE_MULTIPLE_IMAGE: STORYTYPE_FEATURE_MULTIPLE_IMAGE,
    STORYTYPE_MULTI_PIC: STORYTYPE_MULTI_PIC,
    STORYTYPE_EXPLAINER: STORYTYPE_EXPLAINER,
    STORYTYPE_VIDEO: STORYTYPE_VIDEO,
    STORYTYPE_GALLERY: STORYTYPE_GALLERY,
    STORYTYPE_STORY_MAP: STORYTYPE_STORY_MAP,
    STORYTYPE_QA: STORYTYPE_QA,
    STORYTYPE_SHORTHAND: STORYTYPE_SHORTHAND,
    STORYTYPE_LIVE_BLOG24: STORYTYPE_LIVE_BLOG24,
    STORYTYPE_IMOVIE: STORYTYPE_IMOVIE,
    STORYTYPE_LIVE_TWEETS: STORYTYPE_LIVE_TWEETS,
    STORYTYPE_POLL: STORYTYPE_POLL,
    STORYTYPE_QUIZ: STORYTYPE_QUIZ,
    STORYTYPE_HIDDEN: STORYTYPE_HIDDEN,
    STORYTYPE_AUDIO: STORYTYPE_AUDIO,

    DATE_RANGE_TODAY: DATE_RANGE_TODAY,
    DATE_RANGE_HELD_OVER: DATE_RANGE_HELD_OVER,
    DATE_RANGE_NEXT_WEEK: DATE_RANGE_NEXT_WEEK,
    DATE_RANGE_NEXT_MONTH: DATE_RANGE_NEXT_MONTH,

    // COMBINED TYPES
    DateRanges: dateRanges,
    StoryTypesDigital: storyTypeDigital,
    StoryTypesPrint: storyTypePrint,
    StoryTypes: storyTypeOptions,
    StorySections: sectionOptions,
    StorySessions: sessionOptions,
    StoryChannels: channelOptions,

    PilotModeEnabled: true,
    PilotOrganisationIds: NEWSLIST_ORGANISTAION_IDS
};
