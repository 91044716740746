'use strict'

angular.module 'nn.story-digital-quality-checklist.services', []

  .factory 'PyodideService', () ->
    pyodideService = {}

    indexURL = 'https://cdn.jsdelivr.net/pyodide/v0.24.0/full/'
    pyodideService.pyodideReady = self.loadPyodide({ indexURL })

    pyodideService.execute = (data) ->
      code = """
        import textstat

        text = "#{data.replace(/"/g, "'")}"
        readability_score = textstat.flesch_reading_ease(text)
        readability_score
        """
      pyodideService.pyodideReady.then (pyodide) ->
        pyodide.loadPackage("micropip").then ->
          pyodide.runPython('import micropip')
          pyodide.runPython('micropip.install("textstat")').then ->
            pyodide.loadPackage("setuptools").then ->
              pyodide.runPythonAsync(code).catch (error) ->
                console.error('Error executing Python code:', error)

    pyodideService

  .factory 'DigitalQualityChecklistService', () ->
    commands = {}

    commands.getValue = (value, max, type, isForWebDisplay=true) ->
      if type == 'Images' || type == 'Videos' || type == 'Sub_Headings' || type == 'In-text_links'
        if isForWebDisplay
          value
        else
          [value.toString(), null]
      else
        percent = (value/max) * 100
        if isForWebDisplay
          percent.toFixed(0) + '%'
        else
          [value.toString(), percent.toFixed(0).toString()]
    commands

  .constant 'ChecklistItems',
    Headline:
      description: 'A good headline is about 65-75 characters and is engaging and shareable. Use active voice and include a verb. Ask yourself: “Would I click on it?”'
      position: 0
      mandatory: true
      minCount: 65
      maxCount: 75
    SEO_Headline:
      description: 'This helps readers find content on Google so include key terms they\'ll be searching for. Ask: “How would I search for this?”. It must also make sense as a headline (no full stop) and be between 55-65 characters.'
      position: 1
      mandatory: true
      minCount: 55
      maxCount: 65
    SEO_Meta_Description:
      description: 'Your article\'s teaser for Google search engines. It gives a quick summary of your content and entices readers to click by providing a sneak peek of what\'s inside.'
      position: 2
      mandatory: true
      minCount: 100
      maxCount: 160
    Summary:
      description:'Shouldn\'t repeat the headline. Needs to be engaging and no more than 65 characters. It displays on the homepage and social. Key search terms should be used. This is a sentence so must include a full stop at the end.'
      position: 3
      mandatory: true
      minCount: 60
      maxCount: 65
    Body:
      description: 'Every story must be at least 150 words long. There is a sweet spot for subscribers between 300-700 words, but this is a guide only.'
      position: 4
      mandatory: true
      minCount: 150
      maxCount: 999
    Images:
      description: 'The first picture should sit two to three pars below the first video in the story. Generally this will place the photo around six to seven pars into a story depending on ad placement - check the preview below.'
      position: 5
      mandatory: true
      minCount: 1
      maxCount: 1
    Videos:
      description: 'The first video should sit about two pars into a story (dependent on ad placement, check the preview below), so it has time to load while the viewer reads the first few pars of the article.'
      position: 6
      mandatory: false
      minCount: 1
      maxCount: 1
    Sub_Headings:
      description: 'These are best used to break up longer or more complex stories to make them more digestible to the reader. Use the heading 2 option in the style dropdown (and then heading 3 and heading 4 as needed) to create a sub-heading.'
      position: 7
      mandatory: false
      minCount: 1
      maxCount: 1
    'In-text_links':
      description: 'These help direct the reader to content related to the story or background about that story. Data shows that this extends the time readers spend on our site. A minimum of two in-text links are needed in each story.'
      position: 8
      minCount: 2
      maxCount: 2
    readability:
      description: 'This readability score will help us better understand the relationship between reading ease and each masthead’s audience. At this time we are gathering data on what score best engages our audience, so the bar will remain green at all times. The metric behind this score is the Flesch reading ease test. This industry-standard test measures readability on a scale of 1-100. The higher the score the easier the story is to read.'
      position: 9
