'use strict'

angular.module 'nn.story-toolbar.services', []

  .factory 'STORY_VISIBILITY_OPTIONS', (STORY_VISIBILITY) ->
    { PUBLIC, RETRACTED } = STORY_VISIBILITY
    optionsGroup = 'Visibility'
    [
      {
        group: optionsGroup
        label: PUBLIC.label
        value: PUBLIC.value
        css: 'secondary'
      }, {
        group: optionsGroup
        label: RETRACTED.label
        value: RETRACTED.value
        css: 'error'
      }
    ]

  .factory 'STORY_CHANNEL_OPTIONS', (STORY_CHANNEL) ->
    { ALL, WEB, PRINT } = STORY_CHANNEL
    optionsGroup = 'Available to'
    [
      {
        group: optionsGroup
        label: ALL.label
        value: ALL.value
        css: 'secondary'
      }, {
        group: optionsGroup
        label: WEB.label
        value: WEB.value
        css: 'secondary'
      }, {
        group: optionsGroup
        label: PRINT.label
        value: PRINT.value
        css: 'secondary'
      }
    ]

  .factory 'STORY_STATUS_OPTIONS', (STORY_STATUS) ->
    { DRAFT, WAITING, APPROVED } = STORY_STATUS
    optionsGroup = 'Story status'
    [
      {
        group: optionsGroup
        label: DRAFT.label
        value: DRAFT.value
        css: 'secondary'
      }, {
        group: optionsGroup
        label: WAITING.label
        value: WAITING.value
        css: 'alert'
      }, {
        group: optionsGroup
        label: APPROVED.label
        value: APPROVED.value
        css: 'success'
      }
    ]

  .constant 'OrganizationIds',
    AAP: '206'