'use strict'

angular.module 'nn.story-digital-quality-checklist.directives', ['nn.story-digital-quality-checklist.services']

  .directive 'mobileLayout', ->
      link: (scope, element) ->
        element.on 'click', ->
          $(".panel-container").css('width', '421px')

  .directive 'desktopLayout', ->
      link: (scope, element) ->
        element.on 'click', ->
          $(".panel-container").css('width', '95%')

  .directive 'nnStoryDigitalQualityChecklist', ['PyodideService', (PyodideService) ->
        templateUrl: '/templates/story-digital-quality-checklist.html'
        restrict: 'E'
        scope:
          secureUrlForCopy: '='
          secureUrl: '='
        controller: ($scope, $rootScope, $state, $window, DigitalQualityChecklistService, StorypadRequestType, ChecklistItems, StoryAssistanceMessageType, StoryAssistanceRequestType, StoryInfo) ->
          $scope.score = 0
          $scope.enableUpdateScoreButton = false
          $scope.isNewSeoPhase = false
          $scope.bodyText = ''
          $scope.selectedLayout =
            mobile: true
            desktop: false
            withUnderline: false
          $scope.hoveredTypes = {}
          $scope.selectedType = {}
          $scope.elements = []
          $scope.metaDescription = ''
          $scope.$on 'loadingIframeContent', (event, value) ->
            $scope.loadingIframeContent = value
          $scope.$on 'hasDigitalPackageAccess', (event, value) ->
            $scope.hasDigitalPackageAccess = value
            if $scope.hasDigitalPackageAccess and $scope.storyIsWebOnly
              if $scope.elements.length is 8
                elementToInsert =
                  mandatory: ChecklistItems['SEO_Meta_Description'].mandatory
                  type: 'SEO_Meta_Description'
                  currentCount: $scope.metaDescription.length or 0
                  minCount: ChecklistItems['SEO_Meta_Description'].minCount
                  maxCount: ChecklistItems['SEO_Meta_Description'].maxCount
                $scope.elements.splice(2, 0, elementToInsert)
              if $scope.bodyText != ''
                $scope.readabilityScore($scope.bodyText)
          $scope.$on 'storyIsWebOnly', (event, value) ->
            $scope.storyIsWebOnly = value
          $scope.$on StoryAssistanceMessageType.UPDATE_SEO_TITLE, (event, value) ->
            $scope.elements[ChecklistItems['SEO_Headline'].position].currentCount = value.length
          $scope.$on StoryAssistanceMessageType.UPDATE_SEO_DESCRIPTION, (event, value) ->
            $scope.elements[ChecklistItems['SEO_Meta_Description'].position].currentCount = value.length

          isWebPublished = (story)->
            return story.data?.snapshot_pointers?.web?.approved?

          isSeoDescriptionSet = (story) ->
            return (
              story.data?.body?.seo_description? and
                story.data.body?.seo_description.length > 0
            )

          isNewSeoPhase = (story) ->
            return $scope.hasDigitalPackageAccess and
              (!isWebPublished(story) or (isWebPublished(story) and isSeoDescriptionSet(story)))

          removeHtmlTags = (text) ->
            text.replace(/<\/?[^>]+(>|$)/g, '')

          assembleParagraph = (dataArray) ->
            assembledText = dataArray.reduce((text, item) ->
              if item.type is 'paragraph'
                text += removeHtmlTags(item.text) + ' '
              text
            , '')
            assembledText.trim()

          countWordsInParagraph = (paragraph) ->
            cleanText = paragraph.replace(/<\/?[^>]+(>|$)/g, '').replace(/\W+/g, ' ').trim()
            words = cleanText.split(/\s+/)
            if words?
              words = words.filter((word) -> word.length > 0)
              words.length
            else
              0

          $scope.useMobileLayout = () ->
            $scope.selectedLayout['mobile'] = true
            $scope.selectedLayout['desktop'] = false

          $scope.useDeskTopLayout = () ->
            $scope.selectedLayout['mobile'] = false
            $scope.selectedLayout['desktop'] = true

          $scope.getStyle = (layout) ->
            if $scope.selectedLayout[layout]
              {'color': 'blue', 'text-decoration': 'underline', 'cursor': 'pointer'}
            else
              {'color': 'blue', 'cursor': 'pointer'}

          $scope.getLinkStyle = () ->
            {'color': 'blue', 'cursor': 'pointer'}

          $scope.replaceUnderscores = (type) ->
            type.replace(/_/g, ' ')

          $scope.getDivStyle = (elementType) ->
            if $scope.hoveredTypes[elementType]
              {'border-radius': '50%', 'background': '#00aeef', 'display': 'flex', 'cursor': 'pointer'}
            else
              {'border-radius': '50%', 'background': '#afafaf', 'display': 'flex'}

          $scope.onMouseOver = (elementType) ->
            $scope.hoveredTypes[elementType] = true

          $scope.onMouseOut = (elementType) ->
            if !$scope.selectedType[elementType]
              $scope.hoveredTypes[elementType] = false

          $scope.getInfo = (elementType) ->
            if $scope.selectedType[elementType]
              $scope.selectedType[elementType] = false
            else
              $scope.selectedType[elementType] = true
            $scope.description[elementType] = ChecklistItems[elementType].description

          $scope.getPercentForValue = (value,max) ->
            percent = (value/max) * 100
            percent.toFixed(0)

          $scope.getDisplayValue = (value, max, type) ->
            DigitalQualityChecklistService.getValue(value, max, type)

          $scope.getStatusForValue = (value,min,max,type) ->
            if type is 'Body'
              if value >= 1 && value <= 150
                status = 'Overset'
              else if value >= 150 && value <= 699
                status = 'IsValid'
              else
                status = 'Underset'
            else if type is 'Images' or type is 'Videos' or type is 'Sub_Headings'
              if value >= min
                status = 'IsValid'
            else if type is 'In-text_links'
              if value >= min
                status = 'IsValid'
              else
                status = 'Underset'
            else if type is 'SEO_Headline'
              if value >= 40 && value <= 54
                status = 'Underset'
              else if value >= 55 && value <= 65
                status = 'IsValid'
              else
                status = 'Overset'
            else if type is 'SEO_Meta_Description'
              if value >= 65 && value <=99
                status = 'Underset'
              else if value >= 100 && value <=160
                status = 'IsValid'
              else
                status = 'Overset'
            else
              if value >= min && value <= max
                status = 'IsValid' # green
              else if value < min
                status = 'Underset' # orange
              else
                status = 'Overset' # red

          $scope.getStatusForReadabilityValue = (value,showDifficulty) ->
            if value < 30
              if showDifficulty
                if value > 0
                  status = ' (confusing)'
                else
                  status = ''
              else
                status = 'IsValid'
            else if value >=30 and value < 50
              if showDifficulty
                status = ' (difficult)'
              else
                status = 'IsValid'
            else if value >=50 and value < 60
              if showDifficulty
                status = ' (fairly difficult)'
              else
                status = 'IsValid'
            else if value >=60 and value < 70
              if showDifficulty
                status = ' (standard)'
              else
                status = 'IsValid'
            else if value >=70 and value < 80
              if showDifficulty
                status = ' (fairly easy)'
              else
                status = 'IsValid'
            else if value >=80 and value < 90
              if showDifficulty
                status = ' (easy)'
              else
                status = 'IsValid'
            else
              if showDifficulty
                status = ' (very easy)'
              else
                status = 'IsValid'

          $scope.readabilityScore = (data) ->
              PyodideService.execute(data).then (result) ->
                $scope.$apply ->
                  $scope.score = result
                  $scope.enableUpdateScoreButton = false
                  $rootScope.$broadcast(StoryAssistanceRequestType.LATEST_READABILITY_SCORE, result)

          onStorypadMessageReceived = (event, data) =>
            event = event.originalEvent || event
            if typeof event.data == 'object'
              {type, value} = event.data
            else
              if event.data.substring(0, 6) is 'ima://'
                {type, value} = JSON.parse event.data.substr(6)
              else
                try
                  {type, value} = JSON.parse event.data
                catch er
                  type = null
                  value = null
            switch type
                when StorypadRequestType.STORY_SAVE
                  saveState = value.state
                  if saveState is 'saving'
                     $scope.loadingIframeContent = true
                  if saveState is 'success'
                    $scope.elements[ChecklistItems['SEO_Headline'].position].currentCount = value.data?.body?.title_seo?.length or 0
                    assembledParagraph = assembleParagraph(value.data.body.elements)
                    $scope.bodyText = assembledParagraph
                    $scope.metaDescription = value.data.body.seo_description
                    if assembledParagraph != '' and $scope.hasDigitalPackageAccess and $scope.storyIsWebOnly
                      $scope.readabilityScore(assembledParagraph)
                    else
                      $scope.score = 0
                when StorypadRequestType.DIGITAL_QUALITY_CHECK
                  $scope.$apply ->
                    switch value.data.type
                      when 'Body'
                        $scope.elements[ChecklistItems[value.data.type].position] = value.data
                        $scope.enableUpdateScoreButton = true
                        $scope.bodyText = value.data.text
                      when 'SEO_Headline'
                        if !$scope.story_id
                          $scope.elements[ChecklistItems[value.data.type].position].currentCount = 0
                        else
                          $scope.elements[ChecklistItems[value.data.type].position] = value.data
                      else
                        $scope.elements[ChecklistItems[value.data.type].position] = value.data
                    $rootScope.$broadcast(StoryAssistanceRequestType.LATEST_DIGITAL_QUALITY_CHECKLIST_ELEMENTS, $scope.elements)

                when StorypadRequestType.STORY_LOAD
                  loadState = value.state
                  $scope.isNewSeoPhase = isNewSeoPhase(value)
                  if loadState is 'success'
                    $scope.hoveredTypes = {}
                    $scope.selectedType = {}
                    $scope.description = {}
                    $scope.story_id = $state.params.story
                    if !value.data
                      $scope.$apply ->
                        $scope.score = 0
                      value.data =
                        body:
                          elements:[]
                          title_seo:''
                        summary:''
                        title:''

                    assembledParagraph = ''
                    assembledParagraph = assembleParagraph(value.data.body.elements)
                    if assembledParagraph != '' and $scope.hasDigitalPackageAccess and $scope.storyIsWebOnly
                      $scope.readabilityScore(assembledParagraph)
                    else
                      $scope.score = 0
                    counts =
                        Headline: value.data.title?.length or 0
                        SEO_Headline: if $scope.story_id then (value.data.body.title_seo?.length or 0) else 0
                        SEO_Meta_Description: value.data.body.seo_description?.length or 0
                        Summary: value.data.summary?.length or 0
                        Body: 0
                        Images: 0
                        Videos: 0
                        Sub_Headings: 0

                    for item in value.data.body.elements
                        if item.type is 'paragraph' and item.text?
                          counts.Body += countWordsInParagraph(item.text)
                        else if item.type is 'generic' and item.service_type is 'video'
                          counts.Videos += 1
                        else if item.type is 'heading'
                          counts.Sub_Headings += 1
                        else if item.type is 'image'
                          counts.Images += 1
                        else if item.type is 'gallery'
                          countImagesInGallery = (gallery) ->
                            imageCount = 0
                            for galleryItem in gallery.elements
                              if galleryItem.type is 'image'
                                imageCount += 1
                              else if galleryItem.type is 'gallery'
                                imageCount += countImagesInGallery(galleryItem)
                            imageCount

                          counts.Images += countImagesInGallery(item)

                    for key, value of ChecklistItems
                        if value.position < 8
                          $scope.$apply ->
                            $scope.elements[value.position] =
                              mandatory: value.mandatory
                              type: key
                              currentCount: counts[key]
                              minCount: value.minCount
                              maxCount: value.maxCount

          angular.element($window).on 'message', onStorypadMessageReceived
    ]

  .directive 'refreshIframe', ->
    link: (scope, element) ->
      element.on 'click', ->
        time = new Date().getTime()
        $("#story-layout-digital-iframe").attr('src', scope.secureUrl + "&time=" + time)