'use strict'

angular.module 'nn.story-manager.services', []

    .factory 'SilverstoneConfig', (HOSTS) ->
        config =
            api_url: "#{HOSTS.silverstone}/api/v2/"

    .constant 'STORY_VISIBILITY',
        PUBLIC:
            label: 'Public'
            value: 'public'
            enabled: true
        RETRACTED:
            label: 'Retracted'
            value: 'retracted'
            enabled: true
        PRIVATE:
            label: 'Private'
            value: 'private'
            enabled: false

    .constant 'STORY_CHANNEL',
        ALL:
            label: 'Web & Print'
            value: 'all'
            enabled: true
        WEB:
            label: 'Web only'
            value: 'web'
            enabled: true
        PRINT:
            label: 'Print only'
            value: 'print'
            enabled: true

    .constant 'STORY_STATUS',
        DRAFT:
            label: 'Draft'
            value: 'in_progress'
        WAITING:
            label: 'Ready for Approval'
            value: 'waiting'
        APPROVED:
            label: 'Approved'
            value: 'published'

    .constant 'WEB_PREVIEW_DOMAIN',
        AAP: 'content.realestateview.com.au'
        FRM_EDITOR: 'legacy.localnews.com.au'
        CT: 'www.canberratimes.com.au'
        NEWCASTLE_HERALD: 'times.localnews.com.au'

    .factory 'Stories', ($http, $q, Auth, SilverstoneConfig) ->
        ITEMS_PER_PAGE = 10
        PAGE_INDEX = 1
        return {
            getInstance: ->
                stories = []
                meta = null
                loading = false
                params =
                    page_size: ITEMS_PER_PAGE
                    page: 1
                    query: ''
                    # status: ''
                    # time:{ name:'past month', value:'-1m' }
                    organization: ''
                    tag: ''
                    user: null
                    publish_from: ''
                    created_on: ''
                    ordering: ''
                return {
                    all: ->
                        stories
                    search: (nextPage=false) ->
                        if nextPage
                            params.page += PAGE_INDEX
                        else
                            stories = []
                            params.page = 1
                        url = "#{SilverstoneConfig.api_url}story/?"
                        url += "page_size=#{params.page_size}"
                        url += "&page=#{params.page}"
                        url += "&q=#{params.query}" if params.query
                        url += "&pub_status=#{params.status.value}" if params.status?.value
                        url += "&user=#{params.user.value}" if params.user?.value
                        url += "&updated_on=#{params.time.value}" if params.time?.value
                        url += "&publish_from=#{params.publish_from}" if params.publish_from
                        url += "&created_on=#{params.created_on}" if params.created_on
                        if params.tag and (params.tag isnt "All Tags")
                            url += "&tags=#{params.tag}"
                        if params.organization?.id
                            url += "&organization=#{params.organization.id}"
                        else
                            url += "&organization=self"
                        if params.query and params.ordering
                            url += "&ordering=#{params.ordering}"
                        loading = true
                        $http
                            method: 'GET'
                            url: url,
                            cache: false
                            auth: true
                        .success (data) ->
                            stories = stories.concat data.results
                            meta = data.meta
                        .then ->
                            loading = false
                        @
                    more: ->
                        @search true
                    meta: ->
                        meta
                    params: ->
                        params
                    isLoading: ->
                        loading
                }
        }

    .factory 'Snapshots', ($http, Auth, HOSTS, SilverstoneConfig) ->
        Snapshots = ->
            snapshots = null
            all: ->
                snapshots
            refresh: (story_id) ->
                $http
                    method: 'GET'
                    url: "#{SilverstoneConfig.api_url}story/#{story_id}/snapshots/"
                    cache: false
                    auth: true
                .success (data) ->
                    snapshots = data.results
                @
        getInstance: ->
            new Snapshots

    .factory 'StoryTaskStatus', ($http, $q, SilverstoneConfig) ->
        StoryStatus = ->
            data = null
            all: ->
                data
            refresh: (ids) ->
                defer = $q.defer()
                $http
                    method: 'GET'
                    url: "#{SilverstoneConfig.api_url}storytask/storystatus/?ids=" + ids.join ','
                    cache: false
                    auth: true
                .success (data) ->
                    data = data.results
                    defer.resolve data
                defer.promise
        getInstance: ->
            new StoryStatus

    .factory 'Story', (HOSTS, $http, $q, Auth, User, NotificationService ,WEB_PREVIEW_DOMAIN) ->
        API_URL = "#{HOSTS.silverstone}/api/v2/story/"
        commands =
            loadStoryUrl: (story_id, domain) ->
                deferred = $q.defer()
                timestamp = new Date().getTime()
                url = "https://#{domain}/story/#{story_id}/?newsnow_timestamp=#{timestamp}"
                onFetchSuccess = (response) ->
                    deferred.resolve response
                window.fetch(url).then onFetchSuccess
                deferred.promise
            load: (story_id, snapshot_id) ->
                deferred = $q.defer()
                url = "#{API_URL}#{story_id}/"
                url += "?snapshot=#{snapshot_id}" if snapshot_id?
                $http
                    method: 'GET'
                    url: url
                    cache: false
                    auth: true
                .success (response) ->
                    deferred.resolve response
                .error (response) ->
                    deferred.reject response
                deferred.promise
            create: (story) ->
                deferred = $q.defer()
                $http
                    method: 'POST'
                    url: API_URL
                    data: story
                    cache: false
                    auth: true
                .success (response) ->
                    deferred.resolve response
                .error (response) ->
                    deferred.reject response
                deferred.promise
            update: (story_id, snapshot_id, data) ->
                deferred = $q.defer()
                url = "#{API_URL}#{story_id}/"
                url += "?snapshot=#{snapshot_id}" if snapshot_id?
                $http
                    method: 'PUT'
                    url: url
                    data: data
                    cache: false
                    auth: true
                .success (response) ->
                    deferred.resolve response
                .error (response) ->
                    deferred.reject response
                deferred.promise
            createSnapshot: (story_id, snapshot_id, updatedProps={}) ->
                deferred = $q.defer()
                @load story_id, snapshot_id
                    .then (story) =>
                        snapshot = angular.extend {}, story, updatedProps
                        # strip any null values
                        for key, value of snapshot
                            delete snapshot[key] if !value?
                        @update story_id, snapshot_id, snapshot
                            .then (response) ->
                                deferred.resolve response
                            .catch ->
                                deferred.reject 'error creating snapshot'
                    .catch ->
                        deferred.reject 'error loading story to create snapshot'
                deferred.promise
            clone: (story_id) ->
                deferred = $q.defer()
                User.getOrganizations().then (userOrganizations) ->
                    if !userOrganizations?.length
                        deferred.reject 'Before you can clone a story, you need to be a member of an organization.'
                    else
                        loadStoryError = (story) ->
                            deferred.reject 'There was a problem loading the source story, please try again later.'
                        loadStorySuccess = (story) ->
                            defaultUserOrg = userOrganizations[0]
                            userOrgIds = userOrganizations.map (org) -> String(org.id)
                            userIsMemberOfStoryOrg = userOrgIds.indexOf(story.organization) isnt -1
                            if userIsMemberOfStoryOrg
                                cloneStoryOrg = story.organization
                            else
                                cloneStoryOrg = defaultUserOrg.id
                                NotificationService.info "Please note that the cloned story's 'Source' has been set to '#{defaultUserOrg.name}' by default - this can be changed in the Settings panel under 'Source'."
                            data =
                                available_to: story.available_to
                                title: story.title
                                byline: story.byline or ''
                                authors: story.authors or []
                                summary: story.summary or ''
                                body: story.body
                                #
                                tags: story.tags
                                target_lists: story.target_lists
                                #
                                organization: cloneStoryOrg
                                visibility: story.visibility
                                markup_version: story.markup_version
                                #
                                created_by: story.created_by
                                created_on: story.created_on
                                updated_by: story.updated_by
                                updated_on: story.updated_on
                                #
                                # version: story.version
                                # status: story.status
                            createStoryError = (story) ->
                                deferred.reject 'There was a problem cloning this story, please try again later'
                            createStorySuccess = (clonedStory) ->
                                if clonedStory?.id?
                                    deferred.resolve clonedStory
                                else
                                    deferred.reject()
                            commands.create(data).then createStorySuccess, createStoryError
                        commands.load(story_id).then loadStorySuccess, loadStoryError
                deferred.promise
            previewURL: (story_id) ->
                deferred = $q.defer()
                commands.load(story_id).then (story) ->
                    organization = story?.organization
                    if !organization?
                        deferred.reject()
                    else
                        Auth.token().then (token) ->
                            $http
                                method: 'GET',
                                url: "#{HOSTS.suzuka}/api/v1/site/?organization=#{organization}"
                                cache: false
                                auth: true
                            .success (response) ->
                                siteFound = response?.objects?.length > 0
                                if siteFound
                                    sites = response.objects
                                    domain = sites[0].domain
                                    switch domain
                                        when WEB_PREVIEW_DOMAIN.AAP
                                            domain = WEB_PREVIEW_DOMAIN.CT
                                        when WEB_PREVIEW_DOMAIN.FRM_EDITOR
                                            domain = WEB_PREVIEW_DOMAIN.NEWCASTLE_HERALD
                                else
                                    domain = 'test.fairfaxregional.com.au'
                                url = "http://#{domain}/story/#{story_id}/?preview_token=#{token}&adcall=true&adcallkw="
                                deferred.resolve siteFound:siteFound, url:url, domain: domain
                            .error ->
                                deferred.reject()
                deferred.promise
        commands

    .factory 'StoryStatus', ->
        statuses = null
        return {
            all: ->
                statuses
            refresh: ->
                statuses = null
                statuses = [
                        { name:'all status', value:'' }
                        { name:'draft', value:'d' }
                        { name:'published', value:'p' }
                        { name:'private', value:'r' }
                    ]
                @
        }

    .factory 'StoryUser', (Auth) ->
        users = null
        return {
            all: ->
                users
            refresh: ->
                Auth.user().then (user) =>
                    users = null
                    users = [
                            { name:'all stories', value:'' }
                            { name:'my stories', value:user.id }
                        ]
                @
        }

    .factory 'StoryTime', ->
        times = null
        return {
            all: ->
                times
            refresh: ->
                times = null
                times = [
                        { name:'past month', value:'-1m' }
                        { name:'past 24 hours', value:'-1d' }
                        { name:'past week', value:'-1w' }
                        { name:'past year', value:'-1y' }
                        { name:'any time', value:'' }
                    ]
                @
        }

    .factory 'StoryUtils', (STORY_CHANNEL) ->
      { WEB, PRINT } = STORY_CHANNEL;
      singleChannelStory = (story) ->
        story?.available_to?.length is 1
      storyChannelIs = (story, channel) ->
        story?.available_to?[0] is channel
      storyIsWebOnly: (story) ->
        singleChannelStory(story) and storyChannelIs(story, WEB.value)
      storyIsPrintOnly: (story) ->
        singleChannelStory(story) and storyChannelIs(story, PRINT.value)
